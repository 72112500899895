.header-list li {
    display: inline-block;
    margin-right: 9px;
    cursor: pointer;
}

.header-list{
    margin-bottom: unset;
}

@media (max-width:1050px) { 
    .header-list{
        display: none;
    }
    .rightsidebarIcon { 
        display: block!important;
        margin-right: 20px;
        cursor: pointer;
    }
}

.rightsidebarIcon {
    display: none;
}

.rightToggle .rightsidbar{
    position: fixed;
    top: 80px;
    z-index: 1000;
    width: 100px;
    height: 100%;
    background-color: #92c99f;
    box-shadow: 0 0 0 1px rgb(0 0 0 / 5%), 0 1px 1px rgb(0 0 0 / 5%);
    -moz-box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.05), 0 1px 1px rgba(0, 0, 0, 0.05);
    -webkit-box-shadow: 0 0 0 1px rgb(0 0 0 / 5%), 0 1px 1px rgb(0 0 0 / 5%);
    transition: all 1s ease;
    -moz-transition: all 1s ease;
    -o-transition: all 1s ease;
    -ms-transition: all 1s ease;
    -webkit-transition: all 1s ease;
    right: 0px;
}

.rightsidbar{
    position: fixed;
    top: 80px;
    z-index: 1000;
    width: 100px;
    height: 100%;
    background-color: #92c99f;
    box-shadow: 0 0 0 1px rgb(0 0 0 / 5%), 0 1px 1px rgb(0 0 0 / 5%);
    -moz-box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.05), 0 1px 1px rgba(0, 0, 0, 0.05);
    -webkit-box-shadow: 0 0 0 1px rgb(0 0 0 / 5%), 0 1px 1px rgb(0 0 0 / 5%);
    transition: all 1s ease;
    -moz-transition: all 1s ease;
    -o-transition: all 1s ease;
    -ms-transition: all 1s ease;
    -webkit-transition: all 1s ease;
    right: -100%;
}

.sidebarlist{
    list-style-type:none;
    height: 85%;
    overflow: auto;
    margin-bottom: 100px;
}

.sidebarlist li{
    margin-top: 20px;
}